:root {
  color-scheme: dark;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #131a35!important;
  color: #FFF!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-modal-content {
  background-color: transparent!important;
}
.ant-modal-close-x {
  display: none!important;
}
.ant-input,
.ant-input-group-addon  {
  background: transparent!important;
  border: none!important;
  box-shadow: none!important;
  font-size: 20px!important;
  font-weight: 600!important;
  color: #FFF!important;
}
.ant-input-group-addon {
  color: #82d1ca!important;
  font-weight: 600;
}